// @mui
import { Alert, Tooltip, Stack, Typography, Link, Box } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
import AuthWithSocial from './AuthWithSocial';

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuthContext();

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Sign in to SourceAudio's Metastats</Typography>
      </Stack>

      <Alert severity="info" sx={{ mb: 3 }}>
        Don't have a password? Talk to Andrew Harding. Don't know who that is? You probably shouldn't be here.
      </Alert>

      <AuthLoginForm />

    </LoginLayout>
  );
}
