import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { setSession } from './utils';
import SA from '../utils/sourceaudio';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
	isInitialized: false,
	isAuthenticated: false,
	user: null,
};

const reducer = (state, action) => {
	if (action.type === 'INITIAL') {
		return {
			isInitialized: true,
			isAuthenticated: action.payload.isAuthenticated,
			user: action.payload.user,
		};
	}
	if (action.type === 'LOGIN') {
		return {
			...state,
			isAuthenticated: true,
			user: action.payload.user,
		};
	}
	if (action.type === 'REGISTER') {
		return {
			...state,
			isAuthenticated: true,
			user: action.payload.user,
		};
	}
	if (action.type === 'LOGOUT') {
		return {
			...state,
			isAuthenticated: false,
			user: null,
		};
	}

	return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
	children: PropTypes.node,
};

export function AuthProvider({ children }) {
	const [state, dispatch] = useReducer(reducer, initialState);

	const storageAvailable = localStorageAvailable();

	const initialize = useCallback(async () => {
		try {
			const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

			if (accessToken) {
				setSession(accessToken);

				const response = await SA.api('metastats/getUser', {accessToken});

		if (!response || !response.user) {
			throw new Error('Your login has expired');
		} else {

			const { user } = response;

			dispatch({
			type: 'INITIAL',
			payload: {
				isAuthenticated: true,
				user,
			},
			});
		}
			} else {
				dispatch({
					type: 'INITIAL',
					payload: {
						isAuthenticated: false,
						user: null,
					},
				});
			}
		} catch (error) {
			console.error(error);
			dispatch({
				type: 'INITIAL',
				payload: {
					isAuthenticated: false,
					user: null,
				},
			});
		}
	}, [storageAvailable]);

	useEffect(() => {
		initialize();
	}, [initialize]);

	// LOGIN
	const login = useCallback(async (username, password) => {
		const response = await await SA.api('metastats/userLogin', {
			username,
			password,
		});
		const { accessToken, user } = response;

		if (!accessToken || !user) {
			throw new Error(response.error || 'Unknown login error.');
		}
			
		setSession(accessToken);

		dispatch({
			type: 'LOGIN',
			payload: {
				user,
			},
		});
	}, []);

	// REGISTER
	const register = useCallback(async (username, password, firstName, lastName) => {
	return;
	}, []);

	// LOGOUT
	const logout = useCallback(() => {
		setSession(null);
		dispatch({
			type: 'LOGOUT',
		});
	}, []);

	const memoizedValue = useMemo(
		() => ({
			isInitialized: state.isInitialized,
			isAuthenticated: state.isAuthenticated,
			user: state.user,
			method: 'metastats',
			login,
			register,
			logout,
		}),
		[state.isAuthenticated, state.isInitialized, state.user, login, logout, register]
	);

	return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
