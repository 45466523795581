import request from 'superagent';

class SourceAudio {

	/**
	 * Calls the SourceAudio server api.
	 * @param {string} path - the api path, ex. `user/login`
	 * @param {Object} post - key/value object to send as post data
	 * @param {?bool} silent - whether or not to log stuff, if it happens a lot
	 * @return the response json
	 */
	static async api(path, post = {}, silent = false) {
		const accessToken = localStorage.getItem('accessToken');
		if (!path) {
			console.log('ERROR: sa api call needs path');
			return null;
		}
		post.accessToken = accessToken;
		post.bypass = 'metastats-bypass-31792as42344ds1d98d42d';

		const r = await request.post('/sa/' + path).send(post);
		try {
			const data = JSON.parse(r.text);
			if (!silent) console.log('%c[sa]', 'color: #CCC;', path, data);
			return data;
		} catch (e) {
			return { error: r.text };
		}
	}
}

export default SourceAudio;