import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

export const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
export const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
export const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
export const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
export const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
export const PageSix = Loadable(lazy(() => import('../pages/PageSix')));

export const MetastatsTrends = Loadable(lazy(() => import('../pages/MetastatsTrends')));
export const MetastatsMusic = Loadable(lazy(() => import('../pages/MetastatsMusic')));
export const MetastatsPlatform = Loadable(lazy(() => import('../pages/MetastatsPlatform')));
export const MetastatsTopBroadcast = Loadable(lazy(() => import('../pages/MetastatsTopBroadcast')));
export const MetastatsTopLibrary = Loadable(lazy(() => import('../pages/MetastatsTopLibrary')));
export const MetastatsTopCatalogsPlaysAndDownloads = Loadable(lazy(() => import('../pages/MetastatsTopCatalogsPlaysAndDownloads')));
export const MetastatsDetect = Loadable(lazy(() => import('../pages/MetastatsDetect')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const GeneralAnalyticsPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalyticsPage')));
