import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
	Page404,
	PageOne,
	PageTwo,
	PageSix,
	PageFour,
	PageFive,
	LoginPage,
	PageThree,
	GeneralAnalyticsPage,
	MetastatsTrends,
	MetastatsMusic,
	MetastatsPlatform,
	MetastatsTopBroadcast,
	MetastatsDetect,
	MetastatsTopLibrary,
	MetastatsTopCatalogsPlaysAndDownloads,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
	return useRoutes([
		// Auth
		{
			path: 'auth',
			children: [
				{
					path: 'login',
					element: (
						<GuestGuard>
							<LoginPage />
						</GuestGuard>
					),
				},
				{ path: 'login-unprotected', element: <LoginPage /> },
			],
		},
		{
			path: '/',
			element: (
				<AuthGuard>
					<MetastatsTrends />
				</AuthGuard>
			),
			children: [
				{ element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
			],
		},
		{
			path: '/music',
			element: <AuthGuard><MetastatsMusic /></AuthGuard>,
			children: [],
		},
		{
			path: '/music/topcatalogs/playsdownloads',
			element: <AuthGuard><MetastatsTopCatalogsPlaysAndDownloads /></AuthGuard>,
			children: [],
		},
		{
			path: '/platform',
			element: <AuthGuard><MetastatsPlatform /></AuthGuard>,
			children: [],
		},
		{
			path: '/platform/topbroadcastsites',
			element: <AuthGuard><MetastatsTopBroadcast/></AuthGuard>,
			children: [],
		},
		{
			path: '/platform/toplibrarysites',
			element: <AuthGuard><MetastatsTopLibrary /></AuthGuard>,
			children: [],
		},
		{
			path: '/monitoring',
			element: <AuthGuard><MetastatsDetect /></AuthGuard>,
			children: [],
		},
		{
			path: '/dashboard',
			element: (
				<GuestGuard>
					<DashboardLayout />
				</GuestGuard>
			),
			children: [
				{ element: <Navigate to="/dashboard/one" replace />, index: true },
				{ path: 'one', element: <PageOne /> },
				{ path: 'two', element: <PageTwo /> },
				{ path: 'three', element: <PageThree /> },
				{
					path: 'user',
					children: [
						{ element: <Navigate to="/dashboard/user/four" replace />, index: true },
						{ path: 'four', element: <PageFour /> },
						{ path: 'five', element: <PageFive /> },
						{ path: 'six', element: <PageSix /> },
					],
				},
				{ path: 'analytics', element: <GeneralAnalyticsPage /> },
			],
		},
		{
			element: <CompactLayout />,
			children: [{ path: '404', element: <Page404 /> }],
		},
		{ path: '*', element: <Navigate to="/404" replace /> },
	]);
}
